<template>
  <div>
    <el-result icon="warning" title="正在获取授权信息" subTitle="自动跳转，请不要手动关闭！">
      <template slot="icon">
        <img style="width: 400px" src="@/assets/noaccess.svg" alt="">
      </template>
    </el-result>
  </div>
</template>

<!-- auth_code -->
<script>
	export default {
		async created () {
      const { auth_code, number } = this.$route.query;
      if (!auth_code) return this.$router.back(-0);
      const p = {action: 'queryAuth', appid: 'wxd784626b3f1e9f2a', code: auth_code, number: number}
      const res = await this.$http.post(this.$api.URI_WECHAT_COMPONENT, p);
      const { code, msg, result } = res.data;
      let path = '/shops';
      if (code != 0) {
        this.$message.error(msg);
        path = '/apps';
      }
      console.log(result);
      return this.$router.push({ path });
		}
	}
</script>